<template>
  <b-tabs class="page-tabs" v-model="tabIndex" pills>
    <b-tab>
      <template #title>
        <span>
          Dashboard
        </span>
      </template>
      <div class="tab-pane__content">
        <profile-warning
          v-if="!isReportFull"
          :is-report-in-progress="isReportInProgress"
        />
        <profile-tabs-dashboard :data="data" />
        <profile-cta
          :requestType="entityComputed"
          :entityType="entityTypeData"
          :profileEntity="entityData"
          :is-report-full="isReportFull"
          :report-doc-id="reportDocId"
          :is-report-in-progress="isReportInProgress"
        />
      </div>
    </b-tab>
    <b-tab>
      <template #title>
        <span>
          Anagrafica
        </span>
      </template>
      <div class="tab-pane__content">
        <profile-tabs-data :data="data" />
      </div>
    </b-tab>
    <b-tab>
      <template #title>
        <span>
          Visure e Report
        </span>
      </template>
      <div class="tab-pane__content">
        <product-table
          :list="products"
          :requestType="entityComputed"
          single-profile
          :entityType="entityTypeData"
          :profileEntity="entityData"
        />
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import { isNotEmpty } from '../../utils/validators';

const ProfileTabsDashboard = () => import('./ProfileTabsDashboard.vue');
const ProfileTabsData = () => import('./ProfileTabsData.vue');
const ProductTable = () => import('@/components/productTable/ProductTable.vue');
const ProfileWarning = () => import('./ProfileWarning.vue');
const ProfileCta = () => import('./ProfileCta.vue');

export default {
  name: 'ProfileTabs',
  components: {
    ProfileCta,
    ProfileWarning,
    // ProfileTabsCrpayReportMade,
    // ProfileTabsCrpayReportReceived,
    ProfileTabsDashboard,
    ProfileTabsData,
    ProductTable,
  },
  props: {
    data: Object,
    entityType: String,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  computed: {
    entityComputed() {
      return isNotEmpty(this.entityType) ? `pr_${this.entityType}` : 'pr_company';
    },
    products() {
      return this.$store.getters['tableUtils/getTableList'](this.entityComputed) || [];
    },
    entityTypeData() {
      return isNotEmpty(this.entityType) ? this.entityType : '';
    },
    entityData() {
      // person available?
      if (this.entityType === 'person') {
        return {
          name: '',
          surname: '',
          taxCode: '',
          email: '',
        };
      }
      return {
        companyName: this.data?.info?.companyName,
        vatNumber: this.data?.info?.vatNumber,
        email: this.data?.attribute?.mainPecEmail?.contactData,
      };
    },
    hasScoreData() {
      return this.$store.getters['score/hasCurrentScore'];
    },
    isReportFull() {
      return this.data?.reportFull?.status === 'COMPLETED';
    },
    isReportInProgress() {
      return this.data?.reportFull?.status === 'IN_PROGRESS';
    },
    reportDocId() {
      return this.data?.reportFull?.documentId || null;
    },
  },
  methods: {},
};
</script>

<style scoped>

</style>
